<template>
  <a-modal
    v-model="editPlaceVisible"
    :title="(isAdd ? '添加' : '编辑') + '广告位'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="600px"
  >
    <a-form-model ref="ruleForm" class="ruleForm" :model="query" v-bind="layout" labelAlign="right">
      <a-form-model-item
        prop="publisherId"
        :rules="[{ required: true, message: '请选择供应商', trigger: 'change' }]"
        label="供应商"
      >
        <m-select
          style="width: 300px"
          v-model="query.publisherId"
          :dataList="supplierList"
          :onlyLabel="false"
          label="供应商"
        />
      </a-form-model-item>
      <a-form-model-item
        prop="position"
        :rules="[{ required: true, message: '请选择广告样式', trigger: 'change' }]"
        label="广告样式"
      >
        <m-select
          style="width: 300px"
          v-model="query.position"
          :dataList="positionList"
          :onlyLabel="false"
          label="广告样式"
        />
      </a-form-model-item>
      <a-form-model-item
        label="广告位名称"
        :rules="[{ required: true, message: '请输入广告位名称', trigger: 'blur' }]"
        prop="name"
      >
        <a-input style="width: 300px" v-model.trim="query.name" placeholder="请输入广告位名称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="自定义广告位ID" prop="customPlaceId">
        <a-input style="width: 300px" v-model.trim="query.customPlaceId" placeholder="请输入自定义广告位ID"></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="cur && +query.position === 5" label="是否激励视频" prop="adaptiveReward ">
        <a-radio-group v-model="query.adaptiveReward">
          <a-radio :value="1"> 是 </a-radio>
          <a-radio :value="0"> 否 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item prop="strategySts" label="利润策略">
        <a-radio-group v-model="query.strategySts">
          <a-radio value="A"> 自定义策略 </a-radio>
          <a-radio value="S"> 继承供应商详情策略 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <PricingStrategyFormForAdx
        v-if="query.strategySts === 'A'"
        @clearValidate="changeSetForFormquery"
        :showUserDefine="false"
        :query="query"
        @changeQuery="changeFormQuery"
      />
      <a-form-model-item prop="sts" label="选择状态">
        <a-radio-group v-model="query.sts">
          <a-radio value="A"> 开启 </a-radio>
          <a-radio value="S"> 关闭 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <div class="more-setting" v-if="+isAdmin !== 2">
        <a-form-model-item :wrapperCol="{ offset: 3, span: 17 }">
          <a-divider>
            高级配置
            <Arrow @click="isExpend = !isExpend" :class="['arrow', isExpend && 'arrow_up']" />
          </a-divider>
        </a-form-model-item>
      </div>
      <template v-if="isExpend">
        <a-form-model-item v-if="+isAdmin !== 2" label="展示上限" prop="impressFreqHour">
          <a-input
            style="width: 300px"
            @change="handleInputProp($event, 'impressFreqHour')"
            v-model.trim="query.impressFreqHour"
            autocomplete="off"
            placeholder="请输入单设备展示上限(小时)-不限"
          />
        </a-form-model-item>
        <a-form-model-item v-if="+isAdmin !== 2" label="展示上限" prop="impressFreqDay">
          <a-input
            style="width: 300px"
            @change="handleInputProp($event, 'impressFreqDay')"
            v-model.trim="query.impressFreqDay"
            autocomplete="off"
            placeholder="请输入单设备展示上限(天)-不限"
          />
        </a-form-model-item>
        <a-form-model-item v-if="+isAdmin !== 2" label="展示间隔" prop="impressInterval">
          <a-input
            style="width: 300px"
            @change="handleInputProp($event, 'impressInterval')"
            v-model.trim="query.impressInterval"
            autocomplete="off"
            placeholder="请输入单设备展示间隔(秒)-不限"
          />
        </a-form-model-item>
      </template>
    </a-form-model>
  </a-modal>
</template>

<script>
import { addPlace, updatePlace } from '@/apiForManage/flow/place'
import Arrow from '@/assets/icons/arrow.svg?inline'
import PricingStrategyFormForAdx from '@/components/sourceModal/PricingStrategyFormForAdx'
import { mapState } from 'vuex'
export default {
  components: { PricingStrategyFormForAdx, Arrow },
  data () {
    return {
      layout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
      },
      query: {},
      isExpend: true
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    placeInfo: {
      default: () => ({}),
      type: Object
    },
    supplierList: {
      default: () => [],
      type: Array
    },
    positionList: {
      default: () => [],
      type: Array
    },
    publisherIdList: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.isAdmin,
      skip: (state) => state.user.skip,
      role: (state) => state.user.roles[0],
      cur: (state) => state.user.cur,
      mediaAccess: (state) => state.user.mediaAccess
    }),
    editPlaceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          if (this.isAdd) {
            this.query = {
              publisherId: undefined,
              position: undefined,
              name: undefined,
              customPlaceId: undefined,
              // creativeTypeList: [],
              strategySts: 'S',
              bidfloorStrategy: '0',
              bidfloorValue: 0,
              mediaSettleStrategy: '0',
              mediaSettleValue: 0,
              dspSettleStrategy: '0',
              dspSettleValue: 0,
              adaptiveReward: 0,
              dealid: undefined,
              sts: 'A',
              impressFreqHour: undefined,
              impressFreqDay: undefined,
              impressInterval: undefined
            }
            if (this.publisherIdList.length === 1) {
              this.query.publisherId = this.publisherIdList[0]
            }
          } else {
            this.query = {
              ...this.placeInfo
            }
          }
        }
      }
    }
  },
  methods: {
    changeFormQuery (e) {
      this.query = JSON.parse(JSON.stringify(e))
    },
    // 切换竞价底价策略 清空校验
    changeSetForFormquery (data) {
      this.$refs.ruleForm.clearValidate()
    },
    changeProfitRate (e) {
      this.query.profitRate = (e.target.value || '').replace(/[^0-9]/g, '') || undefined
    },
    handleCancel () {
      this.editPlaceVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.isAdd) {
            const { code } = await addPlace(this.query)
            if (code === 200) {
              this.$message.success('添加成功')
              this.editPlaceVisible = false
              this.$emit('editSuccess')
            }
          } else {
            const { code } = await updatePlace(this.query)
            if (code === 200) {
              this.$message.success('编辑成功')
              this.editPlaceVisible = false
              this.$emit('editSuccess')
            }
          }
        } else {
          return false
        }
      })
    },
    handleInputProp (e, prop) {
      if (+e.target.value < 1) {
        this.query[prop] = undefined
      } else if (+e.target.value >= 1) {
        this.query[prop] = Math.ceil(+e.target.value)
      } else {
        this.query[prop] = undefined
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ruleForm {
  height: 400px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #dbdee5;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: #eff0f7;
  }
  ::v-deep .more-setting {
    cursor: pointer;
    .ant-divider-inner-text {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #344563;
      font-weight: 400;
      font-size: 15px;
      font-family: PingFang SC, PingFang SC;
    }
    .arrow {
      width: 20px;
      height: 20px;
      transition: all 0.5s;
      margin-left: 6px;
    }
    .arrow_up {
      transform: rotate(180deg);
    }
  }
}
</style>
